$header--height: 100px !default;
$header--bg-color: #ff0000 !default;

header {
  position: sticky;
  height: $header--height;
  width: 100%;
  background: $header--bg-color;
  .header__top {
    height: 120px;
  }
  @import "navs/nomenue";
}