$nav--bg-color: #ff00ff !default;
$nav-main--font-family: 'Arial' !default;
$nav-main--font-weight: 'bold' !default;
$nav-main--font-size: '40px' !default;

nav {
  width: 100%;
  height: 80px;
  background: $nav--bg-color;
  position: relative;
  .navbar-nav {
    .nav-item {
      .nav-link {
        font-family: $nav-main--font-family;
        font-weight: $nav-main--font-weight;
        font-size: $nav-main--font-size;
      }
    }
  }
}