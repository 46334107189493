$kontakt-icons--a-color-hover: theme('primary-dark') !default;
$kontakt-icons--a-color: theme('primary') !default;

$kontakt-icons--a-color-dark: theme('secondary') !default;
$kontakt-icons--a-color-hover-dark: theme('secondary-dark') !default;
.kontakticons {
  position: relative;
  display: flex;
  flex-direction: row;
  font-size: 70px;
  justify-content: space-around;
  margin-bottom: 30px;
  z-index: 10;
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $kontakt-icons--a-color;
    transition: color 0.3s;
  }
  a:hover {
    color: $kontakt-icons--a-color-hover;
  }
  i {
    padding: 0px 30px 10px 30px;
    width: 130px;
    transform: translateX(-50%);
    position: relative;
    left: 50%;
    svg {
      height: 70px;
    }
    img {
      height: 70px;
    }
  }
  .text {
    font-size: 16px;
    text-align: center;
  }
}
body {
  main {
    .comp1, .comp {
      a {
        i, div {
          color: #a5d5d5;
          transition: color 0.3s;
        }
      }
      a:hover {
        i, div {
          color: #c2eaea !important;
        }
      }
    }
    .comp2 {
      .logo {
        //max-width: 250px !important;
      }
      a {
        i, div {
          color: #0e4194;
          transition: color 0.3s;
        }
      }
      a:hover {
        i, div {
          color: #2b62c8 !important;
        }
      }
    }
  }
}

.dunkle-icons {
  a {
    color: $kontakt-icons--a-color-dark !important;
  }

  a:hover {
    color: $kontakt-icons--a-color-hover-dark !important;
  }
}

.kontakticons-full,
.kontakticons-full-detail {
  a {
    display: flex;
    flex-direction: row;
    i {
      margin-right: 10px;
      img,svg {
        height: 20px;
        max-width: 150px;
      }
    }
  }
  .logo {
    height: 70px;
    max-width: 170px;
  }
  .comp1, .comp {
    a {
      i {
        color: #a5d5d5;
        transition: color 0.3s;
      }
    }
    a:hover {
      i {
        color: #c2eaea !important;
      }
    }
  }
  .comp2 {
    .logo {
      max-width: 250px !important;
    }
    a {
      i {
        color: #0e4194;
        transition: color 0.3s;
      }
    }
    a:hover {
      i {
        color: #2b62c8 !important;
      }
    }
  }
}

.kontakticons-full-detail, .kontakticons-full-detail .comp2, {
  .logo {
    justify-content: center;
    display: flex;
    max-width: unset !important;
    height: unset !important;

    picture {
      img {
        min-width: 350px;
      }
    }
  }

  .text {
    color: #343434 !important;
    font-weight: 200 !important;
  }
}
.container .comp2 {
  a:hover {
    .text {
      color: #343434!important;
      font-weight: 200 !important;
    }
    .btn {
      background: #2b62c8!important;
    }
  }
}