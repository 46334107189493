@include media-breakpoint-down(lg) {
  @include media-breakpoint-up(sm) {
    html {
      body {
        h1 {
          font-size: 50px !important;
        }
        h2 {
          font-size: 26px !important;
        }
        #header {
          height: 100px;
          .header__top {
            height: 100px;
            .header__largelogo {
              top: 0px !important;
              a {
                padding-top: 10px !important;
                padding-bottom: 10px !important;
                img {
                  height: 100% !important;
                  width: unset !important;
                }
              }
            }
          }
        }
        .infobox {
          .infobox-box {
            margin-bottom: 20px !important;
            h3 {
              margin-bottom: 0px !important;
            }
          }
          .box-firstsection {
            height: 180px !important;
          }
        }
        @include media-breakpoint-down(md) {
          .infobox {
            .box-firstsection {
              height: 100px !important;
            }
            .box1, .box2, .box3 {
              padding-right: 90px;
              padding-left: 90px;
            }
          }
          .news-startseite {
            .news {
              margin-right: 90px;
              margin-left: 90px;
            }
          }
        }
      }
    }
  }
  #light-field {
    height: 140px !important;
  }
  .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5,.col-md-6, .col-md-7,.col-md-8,.col-md-9,.col-md-10,.col-md-11,.col-md-12 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }

  .pb-4 {
    padding-bottom: 10px !important;
  }

  body {
    font-size: 1rem !important;
    header {
      .shd-box-main {
        .shd-box:before {
          top: -51px !important;
        }
      }
      .citysilhouette {
        max-height: 50px !important;
      }
      .headerlogo {
        picture {
          img {
            max-height: 160px !important;
          }
        }
      }
      .header__top {
        .header__largelogo {
          margin-left: 150px !important;
        }
      }
      .logo-left {
        left: 0px !important;
        transform: translateX(0px) !important;
        .key-top {
          display: none !important;
        }
      }
      .header__top {
        .logo-right {
          z-index: 9999;
          height: 100px !important;

          .logo-comp {
            top: 15px !important;
            width: 120px !important;
            margin-top: 20px;
          }
          @include media-breakpoint-down(md) {
            .logo-comp {
              margin-top: 0px !important;
            }
          }
          .sewald {
            margin-left: 30px !important;
          }
          .muck {
            margin-right: 30px !important;
          }
          img,svg {
            height: 30px !important;
          }
        }
      }
    }
    main {
      padding-top: 100px !important;
      .teaser {
        max-height: 300px;
        min-height: 300px;
        article {
          h1 {
            font-size: 50px !important;
          }
        }
      }
      .parallax-bilder {
        height: 290px !important;
        min-height: 290px !important;
        picture {
          img {
            width: unset !important;
            min-width: 100% !important;
            position: absolute;
          }
        }
      }
      .infobox {
        margin-left: -20px !important;
        margin-right: -20px !important;
      }
      .parallax-bilder-fixed {
        picture {
          img {
            min-width: 100% !important;
            min-height: 100% !important;
          }
        }
      }
      .tinyMCEbackground-3 {
        padding: 15px;
        p {
          font-size: 15px !important;
        }
      }
      .events {
        display: flex;
        .row {
          .col-md-12 {
            padding-right: 30px !important;
            padding-left: 30px !important;
          }
        }
      }
      hr {
        margin-top: 5px;
      }
      h2 {
        font-size: 22px !important;
      }
      h4 {
        font-size: 22px !important;
      }
      .buttons-modul .btn-modul i {
        width: 90px;
        height: 90px;
        padding: 15px !important;
      }
      .news__content {
        font-size: 0.9rem !important;
      }
    }
    footer {
      width: 100% !important;
      min-height: 400px !important;
      .ilu {
        z-index: 1;
      }
      .grid-container{
        z-index: 999;
      }
    }
  }
}