/* barlow-condensed-200 - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/barlow-condensed-v5-latin-200.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/barlow-condensed-v5-latin-200.eot?#iefix") format("embedded-opentype"), url("../fonts/barlow-condensed-v5-latin-200.woff2") format("woff2"), url("../fonts/barlow-condensed-v5-latin-200.woff") format("woff"), url("../fonts/barlow-condensed-v5-latin-200.ttf") format("truetype"), url("../fonts/barlow-condensed-v5-latin-200.svg#BarlowCondensed") format("svg");
  /* Legacy iOS */
  font-display: swap; }

/* barlow-condensed-regular - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/barlow-condensed-v5-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/barlow-condensed-v5-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/barlow-condensed-v5-latin-regular.woff2") format("woff2"), url("../fonts/barlow-condensed-v5-latin-regular.woff") format("woff"), url("../fonts/barlow-condensed-v5-latin-regular.ttf") format("truetype"), url("../fonts/barlow-condensed-v5-latin-regular.svg#BarlowCondensed") format("svg");
  /* Legacy iOS */
  font-display: swap; }

/* barlow-condensed-600 - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/barlow-condensed-v5-latin-600.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/barlow-condensed-v5-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/barlow-condensed-v5-latin-600.woff2") format("woff2"), url("../fonts/barlow-condensed-v5-latin-600.woff") format("woff"), url("../fonts/barlow-condensed-v5-latin-600.ttf") format("truetype"), url("../fonts/barlow-condensed-v5-latin-600.svg#BarlowCondensed") format("svg");
  /* Legacy iOS */
  font-display: swap; }

/* barlow-condensed-800 - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/barlow-condensed-v5-latin-800.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/barlow-condensed-v5-latin-800.eot?#iefix") format("embedded-opentype"), url("../fonts/barlow-condensed-v5-latin-800.woff2") format("woff2"), url("../fonts/barlow-condensed-v5-latin-800.woff") format("woff"), url("../fonts/barlow-condensed-v5-latin-800.ttf") format("truetype"), url("../fonts/barlow-condensed-v5-latin-800.svg#BarlowCondensed") format("svg");
  /* Legacy iOS */
  font-display: swap; }

.parallax-bilder {
  height: 100%;
  min-height: 400px; }
  .parallax-bilder picture {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%; }
    .parallax-bilder picture img {
      width: 100%;
      min-width: 100%;
      will-change: transform;
      position: absolute; }

.parallax-bilder-fixed picture {
  height: 100%; }
  .parallax-bilder-fixed picture img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.header {
  width: 100%;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  will-change: transform; }
  .header #particles-js {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-image: url("");
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    z-index: 9999; }
    .header #particles-js canvas {
      display: block;
      vertical-align: bottom; }
  .header #particles {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999; }
    .header #particles canvas {
      width: 100%;
      height: 100%; }
  .header picture {
    width: 100%;
    height: auto;
    position: relative; }
    .header picture img {
      width: calc(100% - 200px);
      margin: 100px; }
  .header .header--text {
    position: absolute;
    top: 0px;
    left: 50%;
    font-size: 50px;
    text-transform: uppercase;
    transform: translateX(-50%); }

.kontakticons {
  position: relative;
  display: flex;
  flex-direction: row;
  font-size: 70px;
  justify-content: space-around;
  margin-bottom: 30px;
  z-index: 10; }
  .kontakticons a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: theme("primary");
    transition: color 0.3s; }
  .kontakticons a:hover {
    color: theme("primary-dark"); }
  .kontakticons i {
    padding: 0px 30px 10px 30px;
    width: 130px;
    transform: translateX(-50%);
    position: relative;
    left: 50%; }
    .kontakticons i svg {
      height: 70px; }
    .kontakticons i img {
      height: 70px; }
  .kontakticons .text {
    font-size: 16px;
    text-align: center; }

body main .comp1 a i, body main .comp1 a div, body main .comp a i, body main .comp a div {
  color: #a5d5d5;
  transition: color 0.3s; }

body main .comp1 a:hover i, body main .comp1 a:hover div, body main .comp a:hover i, body main .comp a:hover div {
  color: #c2eaea !important; }

body main .comp2 a i, body main .comp2 a div {
  color: #0e4194;
  transition: color 0.3s; }

body main .comp2 a:hover i, body main .comp2 a:hover div {
  color: #2b62c8 !important; }

.dunkle-icons a {
  color: theme("secondary") !important; }

.dunkle-icons a:hover {
  color: theme("secondary-dark") !important; }

.kontakticons-full a,
.kontakticons-full-detail a {
  display: flex;
  flex-direction: row; }
  .kontakticons-full a i,
  .kontakticons-full-detail a i {
    margin-right: 10px; }
    .kontakticons-full a i img, .kontakticons-full a i svg,
    .kontakticons-full-detail a i img,
    .kontakticons-full-detail a i svg {
      height: 20px;
      max-width: 150px; }

.kontakticons-full .logo,
.kontakticons-full-detail .logo {
  height: 70px;
  max-width: 170px; }

.kontakticons-full .comp1 a i, .kontakticons-full .comp a i,
.kontakticons-full-detail .comp1 a i,
.kontakticons-full-detail .comp a i {
  color: #a5d5d5;
  transition: color 0.3s; }

.kontakticons-full .comp1 a:hover i, .kontakticons-full .comp a:hover i,
.kontakticons-full-detail .comp1 a:hover i,
.kontakticons-full-detail .comp a:hover i {
  color: #c2eaea !important; }

.kontakticons-full .comp2 .logo,
.kontakticons-full-detail .comp2 .logo {
  max-width: 250px !important; }

.kontakticons-full .comp2 a i,
.kontakticons-full-detail .comp2 a i {
  color: #0e4194;
  transition: color 0.3s; }

.kontakticons-full .comp2 a:hover i,
.kontakticons-full-detail .comp2 a:hover i {
  color: #2b62c8 !important; }

.kontakticons-full-detail .logo, .kontakticons-full-detail .comp2 .logo {
  justify-content: center;
  display: flex;
  max-width: unset !important;
  height: unset !important; }
  .kontakticons-full-detail .logo picture img, .kontakticons-full-detail .comp2 .logo picture img {
    min-width: 350px; }

.kontakticons-full-detail .text, .kontakticons-full-detail .comp2 .text {
  color: #343434 !important;
  font-weight: 200 !important; }

.container .comp2 a:hover .text {
  color: #343434 !important;
  font-weight: 200 !important; }

.container .comp2 a:hover .btn {
  background: #2b62c8 !important; }

.teaser {
  width: 100%;
  height: calc(100vh / 2);
  max-height: 770px;
  min-height: 400px;
  position: relative;
  overflow: hidden;
  margin-top: 0px; }
  .teaser .teaser--bg {
    width: 100%;
    height: 110%;
    position: absolute;
    opacity: 0;
    transition: opacity 0.6s; }
    .teaser .teaser--bg img {
      object-fit: cover;
      width: 100%;
      height: 100%; }
  .teaser .active {
    opacity: 1; }
  .teaser .teaser--shadow {
    position: absolute;
    bottom: -10px;
    width: 100vw;
    height: 0px;
    box-shadow: 0px -10px 30px 10px #000000;
    transform: rotate(-0.5deg);
    z-index: 999; }
  .teaser article {
    position: absolute;
    top: 50%;
    left: 0%;
    width: 100vw;
    padding-left: 180px;
    padding-right: 180px; }
    .teaser article h1 {
      font-size: 90px;
      color: #ccc;
      font-weight: 600;
      text-align: left;
      border-bottom: 0px !important;
      mix-blend-mode: color-dodge;
      opacity: 0.5; }
    .teaser article p {
      font-size: 34px;
      color: #ccc;
      opacity: 1;
      text-align: left;
      mix-blend-mode: color-dodge;
      opacity: 0.5; }
    .teaser article .embed-responsive {
      box-shadow: 0px 0px 15px #000;
      background: #007bff;
      border-radius: 15px; }
    .teaser article .fade-in {
      opacity: 0; }
  .teaser .fade-rtl, .teaser .fade-ltr {
    transform: matrix(1, 0, 0, 1, 0, 0);
    transition: all, 0s, 0s;
    transition-duration: 3s, 3s;
    transition-property: transform, opacity;
    transition-timing-function: ease-in-out, linear;
    will-change: opacity, transform;
    opacity: 0; }
  .teaser .fade-rtl {
    transform: translate(50px); }
  .teaser .fade-ltr {
    transform: translate(-50px); }
  .teaser #conversionbuttonindex2 {
    position: absolute;
    right: 20%;
    bottom: 35%;
    opacity: 0;
    font-size: 35px;
    box-shadow: 0px 0px 15px #333;
    padding: 10px 20px 10px 20px;
    transition: all 0.5s ease; }

.has-open, .has-closed {
  position: absolute;
  z-index: 999;
  height: auto;
  transform: translate(-50%, -80px);
  left: 50%;
  background: #ffffff;
  width: 580px;
  display: flex;
  height: 80px;
  font-family: 'Yanone Kaffeesatz'; }
  .has-open .clock, .has-closed .clock {
    width: 85px;
    height: 60px;
    margin-top: 10px;
    border-right: 1px solid #cdc0b0;
    margin-right: 15px; }
    .has-open .clock i, .has-closed .clock i {
      font-size: 60px;
      color: #cdc0b0; }
      .has-open .clock i svg, .has-open .clock i img, .has-closed .clock i svg, .has-closed .clock i img {
        height: 60px;
        transform: translate(10px, -15px); }
  .has-open .text, .has-closed .text {
    font-size: 50px;
    font-weight: bold;
    color: #301610;
    margin-top: 5px; }
  .has-open .text-closed, .has-closed .text-closed {
    font-size: 50px;
    font-weight: bold;
    color: #301610;
    margin-top: 5px; }
  .has-open .zeit, .has-closed .zeit {
    position: relative;
    width: 165px;
    margin-left: 20px;
    margin-top: 10px;
    font-weight: bold;
    font-size: 35px;
    color: #cdc0b0; }

.teaser--shadow {
  width: 100%;
  height: 10px;
  background: transparent;
  transform: translateY(0px);
  z-index: 1001;
  position: absolute;
  box-shadow: 0px -7px 10px rgba(0, 0, 0, 0.5) !important; }

.teaser--main {
  overflow: hidden;
  position: relative;
  margin-left: -30px;
  margin-right: -30px; }

.has-closed {
  width: 450px; }

.teaser--halb {
  width: 100%;
  height: calc(100vh / 1.2);
  max-height: 400px;
  min-height: 300px;
  position: relative;
  overflow: hidden; }
  .teaser--halb .teaser--halb-teaser {
    max-height: 400px;
    min-height: 150px; }
  .teaser--halb picture {
    position: relative;
    width: 100%; }
    .teaser--halb picture img {
      position: relative;
      width: 100%; }

nav {
  width: 100%;
  height: 80px;
  background: #004750;
  position: relative; }
  nav .navbar-nav .nav-item .nav-link {
    font-family: "Barlow Condensed";
    font-weight: 900;
    font-size: 20px; }

.module-video {
  margin-left: -30px;
  margin-right: -30px;
  display: flex;
  position: relative; }
  .module-video .video-shadow {
    position: absolute;
    bottom: -1px;
    left: 0px;
    width: 100%; }
    .module-video .video-shadow img {
      width: 100%;
      bottom: -1px;
      transform: rotate(180deg); }

.infobox {
  margin-left: -30px !important;
  margin-right: -30px !important;
  margin-bottom: 50px;
  background: transparent !important; }
  .infobox .infobox-box {
    box-shadow: 0px 0px 5px #e5e5e5;
    background: #fff;
    padding: 10px; }
    .infobox .infobox-box .box-image {
      width: 100%; }
      .infobox .infobox-box .box-image picture {
        width: 100%; }
        .infobox .infobox-box .box-image picture img {
          width: 100%; }
    .infobox .infobox-box a {
      display: flex;
      justify-content: center; }
      .infobox .infobox-box a .fontawsome-icon {
        width: 32px; }
        .infobox .infobox-box a .fontawsome-icon svg, .infobox .infobox-box a .fontawsome-icon img {
          color: #b3b1b2;
          width: 32px;
          height: auto;
          transition: 0.3s color; }
    .infobox .infobox-box a:hover .fontawsome-icon svg {
      color: #e1e1e1 !important; }
  .infobox h2 {
    color: #fff;
    margin-top: 30px;
    font-size: 20px;
    margin-bottom: 10px; }
  .infobox h3 {
    color: #009cc5;
    font-size: 25px;
    font-weight: 600;
    text-align: center; }
  .infobox .content {
    color: #ffffff;
    margin-bottom: 30px; }
  .infobox .box-firstsection {
    height: 140px; }
  .infobox .box-lastsection {
    color: #ffffff; }
    .infobox .box-lastsection a {
      color: #ffffff; }
      .infobox .box-lastsection a img {
        transition: opacity 0.3s; }
    .infobox .box-lastsection a:hover {
      color: rgba(255, 255, 255, 0.75) !important; }
      .infobox .box-lastsection a:hover img {
        opacity: 0.75; }
  .infobox .box-pic {
    width: 100%; }
    .infobox .box-pic img {
      width: 100%;
      border: 10px solid #ffffff; }

.news-startseite {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 40px 0 20px; }
  .news-startseite .news {
    background: #fff;
    padding: 20px 20px 10px;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% / 3 - 20px);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); }
    .news-startseite .news .news__media .news__media-img-container {
      display: flex;
      flex-direction: column; }
      .news-startseite .news .news__media .news__media-img-container picture {
        display: flex;
        flex-direction: column; }
        .news-startseite .news .news__media .news__media-img-container picture img {
          width: calc(200% / 16 * 9);
          height: calc(100% / 16 * 9); }
    .news-startseite .news a {
      text-decoration: none;
      color: #000;
      font-weight: 400;
      flex-direction: column;
      height: 100%;
      display: flex; }
    .news-startseite .news picture {
      max-width: 100%; }
      .news-startseite .news picture img {
        max-width: 100%; }
    .news-startseite .news .news__headline h4 {
      color: #EB2323;
      margin: 10px 0px 10px 10px; }
    .news-startseite .news .news__content {
      padding: 0 0 0;
      font-size: 18px;
      flex-grow: 2; }
    .news-startseite .news .news--verlinkung {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%; }
      .news-startseite .news .news--verlinkung svg {
        max-width: 60px;
        max-height: 60px;
        height: 60px;
        width: 60px;
        z-index: 1; }
    .news-startseite .news .news--verlinkung-line {
      width: 100%;
      border-top: 1px solid #ccc;
      height: 1px;
      position: absolute; }

.site-map {
  display: flex;
  list-style-type: none;
  margin-left: 0px;
  padding-left: 0px; }
  .site-map li a {
    line-height: 21px;
    font-size: 21px;
    color: #dfaf46 !important;
    white-space: normal;
    text-shadow: -1px -1px 0 #b01a1a;
    font-weight: 900;
    font-size: 21px;
    text-transform: uppercase; }

.sitemap--nebeneinander {
  flex-direction: row;
  justify-content: space-around; }

.buttons-modul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px; }
  .buttons-modul a {
    justify-content: center;
    text-align: center;
    transition: color 0.3s;
    margin: 0px 30px;
    font-weight: 600;
    color: #a5d5d5; }
  .buttons-modul a:first-child {
    margin-left: 0px !important; }
  .buttons-modul a:last-child {
    margin-right: 0px !important; }
  .buttons-modul .btn-modul:before {
    content: ' ';
    float: left; }
  .buttons-modul .btn-modul {
    display: flex;
    justify-content: center;
    display: flex;
    position: relative; }
    .buttons-modul .btn-modul i {
      font-size: 60px;
      padding: 25px;
      border-radius: 10px;
      background: #a5d5d5;
      color: #ffffff;
      margin-bottom: 10px;
      transition: color 0.3s;
      width: 180px;
      height: 180px;
      display: flex;
      transition: color,background 0.3s; }
      .buttons-modul .btn-modul i img, .buttons-modul .btn-modul i svg {
        width: 100%;
        transform: translateY(0px); }
  .buttons-modul a:hover {
    color: #990000; }
    .buttons-modul a:hover i {
      color: #990000;
      background: #990000; }

.bilderslider {
  justify-content: center;
  display: flex;
  border: 10px solid #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  margin-bottom: 30px; }
  .bilderslider .bilder--slider {
    width: 100%;
    height: 394px;
    overflow: hidden;
    position: relative; }
    .bilderslider .bilder--slider div {
      width: 100%;
      height: auto; }
    .bilderslider .bilder--slider picture {
      opacity: 0;
      transition: opacity 0.8s;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%; }
      .bilderslider .bilder--slider picture img {
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px; }
    .bilderslider .bilder--slider .active {
      opacity: 1; }
      .bilderslider .bilder--slider .active picture {
        opacity: 1; }
  .bilderslider .bilder-slider-preview {
    position: absolute;
    bottom: 70px;
    height: 83px;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center; }
    .bilderslider .bilder-slider-preview picture {
      position: relative;
      height: 72px;
      width: 120px;
      padding: 5px;
      margin: 0px 10px;
      cursor: pointer; }
      .bilderslider .bilder-slider-preview picture img {
        width: calc(100% - 10px);
        position: absolute;
        top: 5px;
        left: 5px;
        opacity: 1;
        transition: opacity 0.8s;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        border: 3px solid #ffffff; }
    .bilderslider .bilder-slider-preview .active img {
      opacity: 0.5; }

.bildmittext-box .bildbox {
  border: 10px solid #fff;
  padding: 0px !important;
  box-shadow: 0 0 5px #e5e5e5; }
  .bildmittext-box .bildbox picture {
    position: relative;
    width: 100%;
    height: 100%; }
    .bildmittext-box .bildbox picture img {
      position: relative;
      width: 100%; }

html, body {
  overscroll-behavior-y: none; }

body {
  font-size: 1.3rem !important;
  font-weight: 200 !important;
  overscroll-behavior-y: none;
  color: #333333 !important; }
  body .mobile-footer {
    display: none; }
  body .modal-header {
    border-bottom: 0px; }
    body .modal-header button {
      padding: 0px !important;
      background: none !important;
      border: 0px !important;
      margin-top: -40px !important;
      margin-right: -10px !important; }
      body .modal-header button span {
        font-size: 50px !important; }
  body .modal-content {
    margin-top: 50%; }
  body .modal-body h1 {
    margin-top: 0px; }
  body h1 {
    color: #a5d5d5;
    text-transform: uppercase; }
  body h2 {
    color: #0e4192; }
  body header {
    position: fixed !important;
    z-index: 999;
    background: #fff !important;
    width: 100%; }
    body header .shd-box-main {
      top: 0px !important;
      height: 180px; }
      body header .shd-box-main .shd-box:before {
        top: -47px !important;
        box-shadow: 0px 15px 30px #333;
        width: 150%;
        transform: rotate(0deg) !important; }
    body header .header__top {
      display: flex;
      position: relative;
      border-bottom: 15px solid #e5e5e5;
      height: 120px;
      background: #fff;
      z-index: 9999; }
      body header .header__top .citysilhouette {
        position: absolute;
        left: 0px;
        bottom: 0px;
        height: 100%;
        max-height: 80px;
        width: auto;
        transform: scaleX(-1); }
      body header .header__top .logo-left {
        position: absolute;
        left: 50%;
        transform: translateX(-770px);
        top: 0px;
        display: flex;
        width: 150px;
        margin-left: 20px;
        z-index: 999; }
        body header .header__top .logo-left .logo-signet {
          width: 135px !important;
          height: 230px !important;
          background: rgba(255, 255, 255, 0.9);
          box-shadow: 0px 0px 10px #e5e5e5;
          z-index: 999; }
          body header .header__top .logo-left .logo-signet .signet {
            width: calc(100% - 40px);
            height: auto;
            margin-left: 20px;
            margin-top: 20px;
            margin-right: 20px; }
          body header .header__top .logo-left .logo-signet .key-top {
            position: absolute;
            top: 155px;
            left: 50%;
            transform: translateX(-50%); }
        body header .header__top .logo-left img {
          width: 140px; }
      body header .header__top .logo-right {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        height: 50px;
        display: flex;
        margin-right: 110px;
        border-right: 1px solid #e5e5e5;
        width: 100%;
        justify-content: flex-end;
        z-index: 99999;
        max-width: 300px; }
        body header .header__top .logo-right .sewald a {
          color: #a5d5d5; }
        body header .header__top .logo-right .sewald a:hover {
          color: #c2eaea !important; }
        body header .header__top .logo-right .muck a {
          color: #0e4194; }
        body header .header__top .logo-right .muck a:hover {
          color: #2b62c8 !important; }
        body header .header__top .logo-right .logo-comp {
          width: 100px;
          display: flex;
          flex-direction: column;
          margin: 0px 20px; }
          body header .header__top .logo-right .logo-comp .logo-comp-font {
            width: 100%;
            position: relative;
            text-align: center;
            border-bottom: 1px solid #e5e5e5; }
          body header .header__top .logo-right .logo-comp .logo-comp-icons {
            width: 100%; }
            body header .header__top .logo-right .logo-comp .logo-comp-icons img, body header .header__top .logo-right .logo-comp .logo-comp-icons svg {
              height: 20px;
              margin: 5px; }
        body header .header__top .logo-right a {
          color: #666666;
          transition: color 0.3s; }
          body header .header__top .logo-right a img, body header .header__top .logo-right a svg {
            margin: 0px 10px;
            height: 50px; }
        body header .header__top .logo-right a:hover {
          color: #eb2323; }
    body header .header__largelogo img {
      width: 100%; }
    body header .navbar-toggler {
      position: fixed;
      top: 30px;
      right: 30px;
      z-index: 9999;
      background: #fff !important;
      color: #b3b1b2; }
      body header .navbar-toggler .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(179,177,178, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"); }
    body header .header--icons {
      display: none !important; }
    @media (min-width: 992px) {
      body header .hidden-lg {
        display: none !important; } }
    body header .nav-shadow {
      pointer-events: none;
      position: absolute;
      top: 0px;
      width: 100%; }
      body header .nav-shadow img {
        position: absolute;
        top: 0px;
        width: 100%; }
    body header nav {
      margin-top: 0px;
      padding: 0px !important;
      font-size: 30px;
      font-family: "Barlow Condensed";
      color: #117179;
      background: transparent !important;
      text-transform: uppercase;
      flex-direction: row;
      justify-content: space-between;
      z-index: 999;
      height: 60px !important; }
      body header nav #navbarSupportedContent {
        visibility: hidden;
        background: rgba(255, 255, 255, 0.9) !important;
        opacity: 0;
        transition: visibility 0.3s linear,opacity 0.3s linear; }
        body header nav #navbarSupportedContent ul {
          width: 100% !important;
          height: auto;
          margin-top: 10px;
          margin-bottom: 10px;
          justify-content: space-around;
          align-items: baseline;
          text-shadow: 1px 1px #fff; }
          body header nav #navbarSupportedContent ul li {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            width: calc(100% / 3); }
            body header nav #navbarSupportedContent ul li a {
              color: #1d1d1d;
              font-weight: 400;
              font-size: 30px; }
            body header nav #navbarSupportedContent ul li a:hover {
              color: #009cc5 !important; }
            body header nav #navbarSupportedContent ul li ul {
              margin: 0px;
              padding: 0px; }
              body header nav #navbarSupportedContent ul li ul li {
                font-size: 12px;
                font-weight: 400;
                justify-content: center;
                width: 100%; }
                body header nav #navbarSupportedContent ul li ul li a {
                  color: #666666;
                  font-size: 20px;
                  margin: 0px !important;
                  padding: 0px !important; }
      body header nav .collapsing {
        height: unset !important;
        visibility: visible;
        opacity: 1; }
      body header nav .collapse {
        visibility: visible !important;
        opacity: 1 !important; }
      body header nav .navbar-nav {
        flex-direction: row !important;
        justify-content: space-evenly;
        height: 100%; }
    body header #headerlogo {
      display: flex;
      justify-content: center !important;
      position: relative;
      z-index: 99;
      height: 100%;
      top: 0px; }
      body header #headerlogo a {
        width: 100%;
        display: flex;
        margin-top: 20px; }
        body header #headerlogo a img {
          height: 80%;
          margin-top: 0px;
          width: unset !important; }
  body .header--icons {
    display: flex !important;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 999; }
    body .header--icons .kontakticons {
      font-size: 30px !important;
      padding-top: 25px !important;
      padding-right: 25px !important; }
    body .header--icons a:hover {
      color: #fff4e0 !important; }
    body .header--icons a {
      display: flex;
      color: #fff4e0 !important; }
      body .header--icons a i {
        transform: unset !important;
        padding: 0px 10px 10px;
        width: 40px !important;
        left: unset !important; }
        body .header--icons a i img, body .header--icons a i svg {
          height: 30px !important;
          vertical-align: top !important; }
  body .modal .modal-header {
    margin-bottom: 0px;
    padding-bottom: 0px; }
  body .modal .modal-body {
    margin-top: 0px;
    padding-top: 0px; }
  body .modal h1 {
    font-size: 40px;
    font-weight: 400;
    margin-top: 60px;
    margin-top: 0px !important; }
  body .modal h2 {
    font-size: 25px;
    font-weight: 400;
    color: #0e4192; }
  body main {
    padding-top: 120px;
    background: #ffffff !important;
    z-index: 2; }
    body main .gutschein-bild {
      margin-top: -40px;
      margin-bottom: -60px; }
      body main .gutschein-bild img {
        display: block;
        margin-left: auto;
        margin-right: auto; }
    body main h1 {
      font-size: 40px;
      font-weight: 400;
      color: #a5d5d5;
      margin-top: 60px;
      text-shadow: 1px 1px #fff; }
    body main h2 {
      font-size: 25px;
      font-weight: 400;
      color: #0e4192;
      text-shadow: 1px 1px #fff; }
    body main h4 {
      text-align: center;
      font-weight: 700;
      font-size: 25px;
      font-family: "Barlow Condensed"; }
    body main .tinyMCE:first-child h1 {
      margin-top: 60px; }
    body main .tinyMCE ul {
      margin-left: 0px;
      padding-left: 0px; }
      body main .tinyMCE ul li {
        list-style: none;
        background-image: url("../../../media/icon_check.svg");
        background-repeat: no-repeat;
        padding-left: 30px;
        background-position: left 6px;
        background-size: 20px; }
    body main a {
      font-weight: 400 !important;
      transition: 0.3s color; }
    body main a:hover {
      color: #009cc5 !important; }
    body main .kontakticons {
      max-width: 650px;
      margin-left: auto;
      margin-right: auto; }
      body main .kontakticons i {
        width: unset !important; }
        body main .kontakticons i img, body main .kontakticons i svg {
          height: 100px; }
      body main .kontakticons a {
        color: #006373 !important; }
      body main .kontakticons a:hover {
        color: #43b1b3 !important; }
    body main .tinyMCEbackground-1,
    body main .tinyMCEbackground-2,
    body main .tinyMCEbackground-3 {
      height: 100%;
      padding: 30px;
      color: #fff !important;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      body main .tinyMCEbackground-1 hr,
      body main .tinyMCEbackground-2 hr,
      body main .tinyMCEbackground-3 hr {
        width: 100%; }
      body main .tinyMCEbackground-1 h2,
      body main .tinyMCEbackground-2 h2,
      body main .tinyMCEbackground-3 h2 {
        color: #fff !important; }
    body main hr {
      margin-bottom: 30px; }
    body main .tinyMCEbackground-1 {
      background: #006373; }
    body main .tinyMCEbackground-2 {
      background: #43b1b3; }
    body main .tinyMCEbackground-3 {
      background: #ea5b8f; }
    body main .grid-container {
      z-index: 99;
      background-color: #ffffff; }
    body main .container-fluid, body main .container-xs, body main .container-sm, body main .container-md, body main .container-lg, body main .container-xl {
      padding-right: 30px;
      padding-left: 30px; }
  body .container-color-1:before,
  body .container-color-2:before,
  body .container-color-3:before,
  body .container-color-4:before {
    font-weight: bold;
    font-size: 300px;
    line-height: 286px;
    margin-left: -10px;
    position: absolute; }
  body .container-color-1, body .container-color-2, body .container-color-3 {
    position: relative;
    display: flex; }
    body .container-color-1 > div, body .container-color-2 > div, body .container-color-3 > div {
      position: relative; }
  body .container-color-1 {
    background: #f3f3f3 !important; }
  body .container-color-1:before {
    color: #f2f2f1; }
  body .container-color-2 {
    background: #e5e5e5 !important; }
  body .container-color-2:before {
    color: #cbbc97; }
  body .container-color-3 {
    background: url("../../../media/bg_content.jpeg") repeat-x;
    background-color: #fff; }
    body .container-color-3 .uppercase {
      color: #ddc8a2 !important; }
  body .container-color-4 {
    background: transparent !important; }
  body .container-color-3:before {
    line-height: 282px; }
  body .header--text {
    left: 200px !important;
    top: 80px !important;
    transform: none !important;
    color: #ddc8a2; }
  body footer {
    text-align: center;
    color: #343434;
    background: #ffffff !important;
    z-index: 999999;
    min-height: 300px !important; }
    body footer .footer--shadow {
      position: absolute;
      top: 0px;
      width: 100%; }
      body footer .footer--shadow picture {
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px; }
        body footer .footer--shadow picture img {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 30px; }
    body footer h1, body footer h2, body footer h3, body footer h4, body footer h5, body footer h6 {
      color: #fff !important; }
    body footer a {
      color: #343434 !important;
      font-weight: 200 !important; }
    body footer .logo-footer {
      position: relative;
      margin-top: -20px;
      margin-left: auto;
      margin-right: auto;
      z-index: 999; }
      body footer .logo-footer img {
        width: 300px; }
    body footer .footer--silhouette {
      position: relative;
      border-bottom: 80px solid #b3b1b2; }
      body footer .footer--silhouette i svg {
        width: 100%;
        height: auto; }
        body footer .footer--silhouette i svg .st0 {
          fill: #b3b1b2; }
      body footer .footer--silhouette .footer--copyright {
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: -60px;
        color: #333333; }
        body footer .footer--silhouette .footer--copyright a {
          font-weight: 200 !important;
          color: #333333 !important; }
    body footer .footer--imprint {
      position: relative;
      padding: 20px;
      background: #666666;
      color: #fff !important; }
      body footer .footer--imprint a {
        font-weight: 200 !important;
        color: #fff !important; }
  body .ilu {
    width: 350px;
    height: 286px;
    position: absolute;
    right: 0px;
    bottom: 0px;
    overflow: hidden; }
    body .ilu img {
      width: 100%;
      z-index: 99999;
      opacity: 0;
      transform: translateY(50px);
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; }
  body .visible img {
    opacity: 1;
    transform: translateY(0px) !important; }
  body #table-mauibeach_mailcatcher {
    width: 450px;
    max-width: calc(100% - 10px);
    height: 150px;
    padding: 10px;
    margin-top: 40px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    margin-left: 50%;
    transform: translateX(-50%); }
    body #table-mauibeach_mailcatcher .form-group {
      width: calc(100% - 130px); }
      body #table-mauibeach_mailcatcher .form-group .control-label {
        width: 100%;
        color: #2f2f30;
        text-align: center;
        font-size: 20px;
        font-weight: 700; }
      body #table-mauibeach_mailcatcher .form-group .form-control {
        width: 100%;
        height: 50px;
        padding-left: 20px; }
    body #table-mauibeach_mailcatcher .btn-primary {
      height: 50px;
      margin-top: 38px;
      margin-left: 10px;
      color: black;
      background-color: rgba(190, 188, 194, 0);
      border: 1px solid #5f5e61; }
    body #table-mauibeach_mailcatcher .bird {
      position: absolute;
      left: 0px;
      transform: translateX(-100%); }
  body .events .event {
    display: flex;
    flex-direction: row;
    margin-bottom: 100px; }
    body .events .event .headline {
      font-size: 40px;
      line-height: 27px;
      margin-bottom: 10px;
      color: #006373;
      font-weight: 600; }
    body .events .event .datum {
      font-weight: 600;
      font-size: 30px;
      color: #eb2323; }
    body .events .event .bild {
      display: flex; }
      body .events .event .bild picture {
        position: relative; }
        body .events .event .bild picture img {
          position: relative;
          width: 100%; }
    body .events .event .btn {
      width: 100% !important;
      background: #eb2323 !important;
      color: #ffffff;
      font-size: 30px !important;
      border: 0px;
      padding-top: 25px;
      padding-bottom: 25px;
      border-radius: 0px 0px 10px 10px;
      font-weight: 600 !important; }
  body .nextevent {
    padding: 20px;
    color: #fff !important;
    justify-content: center;
    display: flex; }
    body .nextevent .headline {
      font-size: 40px;
      font-weight: 600;
      text-align: center; }
    body .nextevent .date {
      text-align: center;
      font-size: 30px;
      margin-bottom: 20px; }
    body .nextevent .ticketbtn {
      text-align: center;
      justify-content: center;
      display: flex;
      margin-left: auto;
      margin-right: auto;
      width: 75%; }
      body .nextevent .ticketbtn a {
        width: 100%; }
        body .nextevent .ticketbtn a .btn {
          color: #fff !important;
          font-size: 1.1rem !important;
          width: 100%;
          padding-top: 12px !important;
          padding-bottom: 12px !important;
          background: #006373;
          border-color: #006373; }
  body .nextevent-bg-1 {
    background: #006373;
    color: #fff; }
    body .nextevent-bg-1 .btn-primary {
      background: #43b1b3; }
  body .nextevent-bg-2 {
    background: #43b1b3; }
  body .nextevent-bg-3 {
    background: #ea5b8f; }
  body .bildergalerie--article {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1; }
    body .bildergalerie--article .teaser--bg {
      width: 100%;
      height: 100%;
      position: fixed;
      opacity: 0;
      transition: opacity 0.6s;
      display: flex;
      object-fit: cover; }
      body .bildergalerie--article .teaser--bg picture {
        height: 100%;
        width: 100%;
        object-fit: cover; }
        body .bildergalerie--article .teaser--bg picture img {
          height: 100%;
          width: 100%;
          object-fit: cover; }
    body .bildergalerie--article .active {
      opacity: 1; }

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --secondary: #0000ff;
  --primary: #a5d5d5;
  --primary-dark: #990000;
  --primary-light: #990000;
  --primary-light-hover: #00ff00;
  --btn-primary-text-hover: #fff;
  --btn-primary-text: #212529;
  --btn-primary-bg: #ffa401;
  --body-bg-hover: #e0e0e0;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Barlow Condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.3rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #a5d5d5;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.container,
.container-fluid,
.container-xs,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.container {
  max-width: 540px; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 720px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 960px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 1140px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1200px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #b8b8ff; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #7a7aff; }

.table-hover .table-secondary:hover {
  background-color: #9f9fff; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #9f9fff; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #e6f3f3; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #d0e9e9; }

.table-hover .table-primary:hover {
  background-color: #d5ebeb; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #d5ebeb; }

.table-primary-dark,
.table-primary-dark > th,
.table-primary-dark > td {
  background-color: #e2b8b8; }

.table-primary-dark th,
.table-primary-dark td,
.table-primary-dark thead th,
.table-primary-dark tbody + tbody {
  border-color: #ca7a7a; }

.table-hover .table-primary-dark:hover {
  background-color: #dba6a6; }
  .table-hover .table-primary-dark:hover > td,
  .table-hover .table-primary-dark:hover > th {
    background-color: #dba6a6; }

.table-primary-light,
.table-primary-light > th,
.table-primary-light > td {
  background-color: #e2b8b8; }

.table-primary-light th,
.table-primary-light td,
.table-primary-light thead th,
.table-primary-light tbody + tbody {
  border-color: #ca7a7a; }

.table-hover .table-primary-light:hover {
  background-color: #dba6a6; }
  .table-hover .table-primary-light:hover > td,
  .table-hover .table-primary-light:hover > th {
    background-color: #dba6a6; }

.table-primary-light-hover,
.table-primary-light-hover > th,
.table-primary-light-hover > td {
  background-color: #b8ffb8; }

.table-primary-light-hover th,
.table-primary-light-hover td,
.table-primary-light-hover thead th,
.table-primary-light-hover tbody + tbody {
  border-color: #7aff7a; }

.table-hover .table-primary-light-hover:hover {
  background-color: #9fff9f; }
  .table-hover .table-primary-light-hover:hover > td,
  .table-hover .table-primary-light-hover:hover > th {
    background-color: #9fff9f; }

.table-btn-primary-text-hover,
.table-btn-primary-text-hover > th,
.table-btn-primary-text-hover > td {
  background-color: white; }

.table-btn-primary-text-hover th,
.table-btn-primary-text-hover td,
.table-btn-primary-text-hover thead th,
.table-btn-primary-text-hover tbody + tbody {
  border-color: white; }

.table-hover .table-btn-primary-text-hover:hover {
  background-color: #f2f2f2; }
  .table-hover .table-btn-primary-text-hover:hover > td,
  .table-hover .table-btn-primary-text-hover:hover > th {
    background-color: #f2f2f2; }

.table-btn-primary-text,
.table-btn-primary-text > th,
.table-btn-primary-text > td {
  background-color: #c1c2c3; }

.table-btn-primary-text th,
.table-btn-primary-text td,
.table-btn-primary-text thead th,
.table-btn-primary-text tbody + tbody {
  border-color: #8c8e90; }

.table-hover .table-btn-primary-text:hover {
  background-color: #b4b5b6; }
  .table-hover .table-btn-primary-text:hover > td,
  .table-hover .table-btn-primary-text:hover > th {
    background-color: #b4b5b6; }

.table-btn-primary-bg,
.table-btn-primary-bg > th,
.table-btn-primary-bg > td {
  background-color: #ffe6b8; }

.table-btn-primary-bg th,
.table-btn-primary-bg td,
.table-btn-primary-bg thead th,
.table-btn-primary-bg tbody + tbody {
  border-color: #ffd07b; }

.table-hover .table-btn-primary-bg:hover {
  background-color: #ffdd9f; }
  .table-hover .table-btn-primary-bg:hover > td,
  .table-hover .table-btn-primary-bg:hover > th {
    background-color: #ffdd9f; }

.table-body-bg-hover,
.table-body-bg-hover > th,
.table-body-bg-hover > td {
  background-color: #f6f6f6; }

.table-body-bg-hover th,
.table-body-bg-hover td,
.table-body-bg-hover thead th,
.table-body-bg-hover tbody + tbody {
  border-color: #efefef; }

.table-hover .table-body-bg-hover:hover {
  background-color: #e9e9e9; }
  .table-hover .table-body-bg-hover:hover > td,
  .table-hover .table-body-bg-hover:hover > th {
    background-color: #e9e9e9; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-secondary {
  color: #fff;
  background-color: #0000ff;
  border-color: #0000ff; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #0000d9;
    border-color: #0000cc; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #0000d9;
    border-color: #0000cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 38, 255, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #0000ff;
    border-color: #0000ff; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #0000cc;
    border-color: #0000bf; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 38, 255, 0.5); }

.btn-primary {
  color: #212529;
  background-color: #a5d5d5;
  border-color: #a5d5d5; }
  .btn-primary:hover {
    color: #212529;
    background-color: #8bc9c9;
    border-color: #82c5c5; }
  .btn-primary:focus, .btn-primary.focus {
    color: #212529;
    background-color: #8bc9c9;
    border-color: #82c5c5;
    box-shadow: 0 0 0 0.2rem rgba(145, 187, 187, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #212529;
    background-color: #a5d5d5;
    border-color: #a5d5d5; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #212529;
    background-color: #82c5c5;
    border-color: #7ac1c1; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(145, 187, 187, 0.5); }

.btn-primary-dark {
  color: #fff;
  background-color: #990000;
  border-color: #990000; }
  .btn-primary-dark:hover {
    color: #fff;
    background-color: #730000;
    border-color: #660000; }
  .btn-primary-dark:focus, .btn-primary-dark.focus {
    color: #fff;
    background-color: #730000;
    border-color: #660000;
    box-shadow: 0 0 0 0.2rem rgba(168, 38, 38, 0.5); }
  .btn-primary-dark.disabled, .btn-primary-dark:disabled {
    color: #fff;
    background-color: #990000;
    border-color: #990000; }
  .btn-primary-dark:not(:disabled):not(.disabled):active, .btn-primary-dark:not(:disabled):not(.disabled).active,
  .show > .btn-primary-dark.dropdown-toggle {
    color: #fff;
    background-color: #660000;
    border-color: #590000; }
    .btn-primary-dark:not(:disabled):not(.disabled):active:focus, .btn-primary-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(168, 38, 38, 0.5); }

.btn-primary-light {
  color: #fff;
  background-color: #990000;
  border-color: #990000; }
  .btn-primary-light:hover {
    color: #fff;
    background-color: #730000;
    border-color: #660000; }
  .btn-primary-light:focus, .btn-primary-light.focus {
    color: #fff;
    background-color: #730000;
    border-color: #660000;
    box-shadow: 0 0 0 0.2rem rgba(168, 38, 38, 0.5); }
  .btn-primary-light.disabled, .btn-primary-light:disabled {
    color: #fff;
    background-color: #990000;
    border-color: #990000; }
  .btn-primary-light:not(:disabled):not(.disabled):active, .btn-primary-light:not(:disabled):not(.disabled).active,
  .show > .btn-primary-light.dropdown-toggle {
    color: #fff;
    background-color: #660000;
    border-color: #590000; }
    .btn-primary-light:not(:disabled):not(.disabled):active:focus, .btn-primary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(168, 38, 38, 0.5); }

.btn-primary-light-hover {
  color: #fff;
  background-color: #00ff00;
  border-color: #00ff00; }
  .btn-primary-light-hover:hover {
    color: #fff;
    background-color: #00d900;
    border-color: #00cc00; }
  .btn-primary-light-hover:focus, .btn-primary-light-hover.focus {
    color: #fff;
    background-color: #00d900;
    border-color: #00cc00;
    box-shadow: 0 0 0 0.2rem rgba(38, 255, 38, 0.5); }
  .btn-primary-light-hover.disabled, .btn-primary-light-hover:disabled {
    color: #fff;
    background-color: #00ff00;
    border-color: #00ff00; }
  .btn-primary-light-hover:not(:disabled):not(.disabled):active, .btn-primary-light-hover:not(:disabled):not(.disabled).active,
  .show > .btn-primary-light-hover.dropdown-toggle {
    color: #fff;
    background-color: #00cc00;
    border-color: #00bf00; }
    .btn-primary-light-hover:not(:disabled):not(.disabled):active:focus, .btn-primary-light-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-light-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 255, 38, 0.5); }

.btn-btn-primary-text-hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff; }
  .btn-btn-primary-text-hover:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-btn-primary-text-hover:focus, .btn-btn-primary-text-hover.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-btn-primary-text-hover.disabled, .btn-btn-primary-text-hover:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-btn-primary-text-hover:not(:disabled):not(.disabled):active, .btn-btn-primary-text-hover:not(:disabled):not(.disabled).active,
  .show > .btn-btn-primary-text-hover.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-btn-primary-text-hover:not(:disabled):not(.disabled):active:focus, .btn-btn-primary-text-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-btn-primary-text-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-btn-primary-text {
  color: #fff;
  background-color: #212529;
  border-color: #212529; }
  .btn-btn-primary-text:hover {
    color: #fff;
    background-color: #101214;
    border-color: #0a0c0d; }
  .btn-btn-primary-text:focus, .btn-btn-primary-text.focus {
    color: #fff;
    background-color: #101214;
    border-color: #0a0c0d;
    box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }
  .btn-btn-primary-text.disabled, .btn-btn-primary-text:disabled {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
  .btn-btn-primary-text:not(:disabled):not(.disabled):active, .btn-btn-primary-text:not(:disabled):not(.disabled).active,
  .show > .btn-btn-primary-text.dropdown-toggle {
    color: #fff;
    background-color: #0a0c0d;
    border-color: #050506; }
    .btn-btn-primary-text:not(:disabled):not(.disabled):active:focus, .btn-btn-primary-text:not(:disabled):not(.disabled).active:focus,
    .show > .btn-btn-primary-text.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }

.btn-btn-primary-bg {
  color: #212529;
  background-color: #ffa401;
  border-color: #ffa401; }
  .btn-btn-primary-bg:hover {
    color: #fff;
    background-color: #da8c00;
    border-color: #cd8400; }
  .btn-btn-primary-bg:focus, .btn-btn-primary-bg.focus {
    color: #fff;
    background-color: #da8c00;
    border-color: #cd8400;
    box-shadow: 0 0 0 0.2rem rgba(222, 145, 7, 0.5); }
  .btn-btn-primary-bg.disabled, .btn-btn-primary-bg:disabled {
    color: #212529;
    background-color: #ffa401;
    border-color: #ffa401; }
  .btn-btn-primary-bg:not(:disabled):not(.disabled):active, .btn-btn-primary-bg:not(:disabled):not(.disabled).active,
  .show > .btn-btn-primary-bg.dropdown-toggle {
    color: #fff;
    background-color: #cd8400;
    border-color: #c07b00; }
    .btn-btn-primary-bg:not(:disabled):not(.disabled):active:focus, .btn-btn-primary-bg:not(:disabled):not(.disabled).active:focus,
    .show > .btn-btn-primary-bg.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 145, 7, 0.5); }

.btn-body-bg-hover {
  color: #212529;
  background-color: #e0e0e0;
  border-color: #e0e0e0; }
  .btn-body-bg-hover:hover {
    color: #212529;
    background-color: #cdcdcd;
    border-color: #c7c7c7; }
  .btn-body-bg-hover:focus, .btn-body-bg-hover.focus {
    color: #212529;
    background-color: #cdcdcd;
    border-color: #c7c7c7;
    box-shadow: 0 0 0 0.2rem rgba(195, 196, 197, 0.5); }
  .btn-body-bg-hover.disabled, .btn-body-bg-hover:disabled {
    color: #212529;
    background-color: #e0e0e0;
    border-color: #e0e0e0; }
  .btn-body-bg-hover:not(:disabled):not(.disabled):active, .btn-body-bg-hover:not(:disabled):not(.disabled).active,
  .show > .btn-body-bg-hover.dropdown-toggle {
    color: #212529;
    background-color: #c7c7c7;
    border-color: silver; }
    .btn-body-bg-hover:not(:disabled):not(.disabled):active:focus, .btn-body-bg-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-body-bg-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(195, 196, 197, 0.5); }

.btn-outline-secondary {
  color: #0000ff;
  border-color: #0000ff; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #0000ff;
    border-color: #0000ff; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 255, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #0000ff;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #0000ff;
    border-color: #0000ff; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 255, 0.5); }

.btn-outline-primary {
  color: #a5d5d5;
  border-color: #a5d5d5; }
  .btn-outline-primary:hover {
    color: #212529;
    background-color: #a5d5d5;
    border-color: #a5d5d5; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(165, 213, 213, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #a5d5d5;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #a5d5d5;
    border-color: #a5d5d5; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(165, 213, 213, 0.5); }

.btn-outline-primary-dark {
  color: #990000;
  border-color: #990000; }
  .btn-outline-primary-dark:hover {
    color: #fff;
    background-color: #990000;
    border-color: #990000; }
  .btn-outline-primary-dark:focus, .btn-outline-primary-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(153, 0, 0, 0.5); }
  .btn-outline-primary-dark.disabled, .btn-outline-primary-dark:disabled {
    color: #990000;
    background-color: transparent; }
  .btn-outline-primary-dark:not(:disabled):not(.disabled):active, .btn-outline-primary-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-dark.dropdown-toggle {
    color: #fff;
    background-color: #990000;
    border-color: #990000; }
    .btn-outline-primary-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(153, 0, 0, 0.5); }

.btn-outline-primary-light {
  color: #990000;
  border-color: #990000; }
  .btn-outline-primary-light:hover {
    color: #fff;
    background-color: #990000;
    border-color: #990000; }
  .btn-outline-primary-light:focus, .btn-outline-primary-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(153, 0, 0, 0.5); }
  .btn-outline-primary-light.disabled, .btn-outline-primary-light:disabled {
    color: #990000;
    background-color: transparent; }
  .btn-outline-primary-light:not(:disabled):not(.disabled):active, .btn-outline-primary-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-light.dropdown-toggle {
    color: #fff;
    background-color: #990000;
    border-color: #990000; }
    .btn-outline-primary-light:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(153, 0, 0, 0.5); }

.btn-outline-primary-light-hover {
  color: #00ff00;
  border-color: #00ff00; }
  .btn-outline-primary-light-hover:hover {
    color: #fff;
    background-color: #00ff00;
    border-color: #00ff00; }
  .btn-outline-primary-light-hover:focus, .btn-outline-primary-light-hover.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 255, 0, 0.5); }
  .btn-outline-primary-light-hover.disabled, .btn-outline-primary-light-hover:disabled {
    color: #00ff00;
    background-color: transparent; }
  .btn-outline-primary-light-hover:not(:disabled):not(.disabled):active, .btn-outline-primary-light-hover:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-light-hover.dropdown-toggle {
    color: #fff;
    background-color: #00ff00;
    border-color: #00ff00; }
    .btn-outline-primary-light-hover:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-light-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-light-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 255, 0, 0.5); }

.btn-outline-btn-primary-text-hover {
  color: #fff;
  border-color: #fff; }
  .btn-outline-btn-primary-text-hover:hover {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-btn-primary-text-hover:focus, .btn-outline-btn-primary-text-hover.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-btn-primary-text-hover.disabled, .btn-outline-btn-primary-text-hover:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-btn-primary-text-hover:not(:disabled):not(.disabled):active, .btn-outline-btn-primary-text-hover:not(:disabled):not(.disabled).active,
  .show > .btn-outline-btn-primary-text-hover.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-btn-primary-text-hover:not(:disabled):not(.disabled):active:focus, .btn-outline-btn-primary-text-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-btn-primary-text-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-btn-primary-text {
  color: #212529;
  border-color: #212529; }
  .btn-outline-btn-primary-text:hover {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
  .btn-outline-btn-primary-text:focus, .btn-outline-btn-primary-text.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }
  .btn-outline-btn-primary-text.disabled, .btn-outline-btn-primary-text:disabled {
    color: #212529;
    background-color: transparent; }
  .btn-outline-btn-primary-text:not(:disabled):not(.disabled):active, .btn-outline-btn-primary-text:not(:disabled):not(.disabled).active,
  .show > .btn-outline-btn-primary-text.dropdown-toggle {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
    .btn-outline-btn-primary-text:not(:disabled):not(.disabled):active:focus, .btn-outline-btn-primary-text:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-btn-primary-text.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }

.btn-outline-btn-primary-bg {
  color: #ffa401;
  border-color: #ffa401; }
  .btn-outline-btn-primary-bg:hover {
    color: #212529;
    background-color: #ffa401;
    border-color: #ffa401; }
  .btn-outline-btn-primary-bg:focus, .btn-outline-btn-primary-bg.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 164, 1, 0.5); }
  .btn-outline-btn-primary-bg.disabled, .btn-outline-btn-primary-bg:disabled {
    color: #ffa401;
    background-color: transparent; }
  .btn-outline-btn-primary-bg:not(:disabled):not(.disabled):active, .btn-outline-btn-primary-bg:not(:disabled):not(.disabled).active,
  .show > .btn-outline-btn-primary-bg.dropdown-toggle {
    color: #212529;
    background-color: #ffa401;
    border-color: #ffa401; }
    .btn-outline-btn-primary-bg:not(:disabled):not(.disabled):active:focus, .btn-outline-btn-primary-bg:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-btn-primary-bg.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 164, 1, 0.5); }

.btn-outline-body-bg-hover {
  color: #e0e0e0;
  border-color: #e0e0e0; }
  .btn-outline-body-bg-hover:hover {
    color: #212529;
    background-color: #e0e0e0;
    border-color: #e0e0e0; }
  .btn-outline-body-bg-hover:focus, .btn-outline-body-bg-hover.focus {
    box-shadow: 0 0 0 0.2rem rgba(224, 224, 224, 0.5); }
  .btn-outline-body-bg-hover.disabled, .btn-outline-body-bg-hover:disabled {
    color: #e0e0e0;
    background-color: transparent; }
  .btn-outline-body-bg-hover:not(:disabled):not(.disabled):active, .btn-outline-body-bg-hover:not(:disabled):not(.disabled).active,
  .show > .btn-outline-body-bg-hover.dropdown-toggle {
    color: #212529;
    background-color: #e0e0e0;
    border-color: #e0e0e0; }
    .btn-outline-body-bg-hover:not(:disabled):not(.disabled):active:focus, .btn-outline-body-bg-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-body-bg-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(224, 224, 224, 0.5); }

.btn-link {
  font-weight: 400;
  color: #a5d5d5;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-xs, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-xs, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-xs, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-xs, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-xs, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-xs, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-xs, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-xs, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-xs, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-xs, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-xs, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-secondary {
  color: #000085;
  background-color: #b8b8ff; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #000085;
    background-color: #9f9fff; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #000085;
    border-color: #000085; }

.list-group-item-primary {
  color: #566f6f;
  background-color: #e6f3f3; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #566f6f;
    background-color: #d5ebeb; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #566f6f;
    border-color: #566f6f; }

.list-group-item-primary-dark {
  color: #500000;
  background-color: #e2b8b8; }
  .list-group-item-primary-dark.list-group-item-action:hover, .list-group-item-primary-dark.list-group-item-action:focus {
    color: #500000;
    background-color: #dba6a6; }
  .list-group-item-primary-dark.list-group-item-action.active {
    color: #fff;
    background-color: #500000;
    border-color: #500000; }

.list-group-item-primary-light {
  color: #500000;
  background-color: #e2b8b8; }
  .list-group-item-primary-light.list-group-item-action:hover, .list-group-item-primary-light.list-group-item-action:focus {
    color: #500000;
    background-color: #dba6a6; }
  .list-group-item-primary-light.list-group-item-action.active {
    color: #fff;
    background-color: #500000;
    border-color: #500000; }

.list-group-item-primary-light-hover {
  color: #008500;
  background-color: #b8ffb8; }
  .list-group-item-primary-light-hover.list-group-item-action:hover, .list-group-item-primary-light-hover.list-group-item-action:focus {
    color: #008500;
    background-color: #9fff9f; }
  .list-group-item-primary-light-hover.list-group-item-action.active {
    color: #fff;
    background-color: #008500;
    border-color: #008500; }

.list-group-item-btn-primary-text-hover {
  color: #858585;
  background-color: white; }
  .list-group-item-btn-primary-text-hover.list-group-item-action:hover, .list-group-item-btn-primary-text-hover.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-btn-primary-text-hover.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-btn-primary-text {
  color: #111315;
  background-color: #c1c2c3; }
  .list-group-item-btn-primary-text.list-group-item-action:hover, .list-group-item-btn-primary-text.list-group-item-action:focus {
    color: #111315;
    background-color: #b4b5b6; }
  .list-group-item-btn-primary-text.list-group-item-action.active {
    color: #fff;
    background-color: #111315;
    border-color: #111315; }

.list-group-item-btn-primary-bg {
  color: #855501;
  background-color: #ffe6b8; }
  .list-group-item-btn-primary-bg.list-group-item-action:hover, .list-group-item-btn-primary-bg.list-group-item-action:focus {
    color: #855501;
    background-color: #ffdd9f; }
  .list-group-item-btn-primary-bg.list-group-item-action.active {
    color: #fff;
    background-color: #855501;
    border-color: #855501; }

.list-group-item-body-bg-hover {
  color: #747474;
  background-color: #f6f6f6; }
  .list-group-item-body-bg-hover.list-group-item-action:hover, .list-group-item-body-bg-hover.list-group-item-action:focus {
    color: #747474;
    background-color: #e9e9e9; }
  .list-group-item-body-bg-hover.list-group-item-action.active {
    color: #fff;
    background-color: #747474;
    border-color: #747474; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-secondary {
  background-color: #0000ff !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #0000cc !important; }

.bg-primary {
  background-color: #a5d5d5 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #82c5c5 !important; }

.bg-primary-dark {
  background-color: #990000 !important; }

a.bg-primary-dark:hover, a.bg-primary-dark:focus,
button.bg-primary-dark:hover,
button.bg-primary-dark:focus {
  background-color: #660000 !important; }

.bg-primary-light {
  background-color: #990000 !important; }

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #660000 !important; }

.bg-primary-light-hover {
  background-color: #00ff00 !important; }

a.bg-primary-light-hover:hover, a.bg-primary-light-hover:focus,
button.bg-primary-light-hover:hover,
button.bg-primary-light-hover:focus {
  background-color: #00cc00 !important; }

.bg-btn-primary-text-hover {
  background-color: #fff !important; }

a.bg-btn-primary-text-hover:hover, a.bg-btn-primary-text-hover:focus,
button.bg-btn-primary-text-hover:hover,
button.bg-btn-primary-text-hover:focus {
  background-color: #e6e6e6 !important; }

.bg-btn-primary-text {
  background-color: #212529 !important; }

a.bg-btn-primary-text:hover, a.bg-btn-primary-text:focus,
button.bg-btn-primary-text:hover,
button.bg-btn-primary-text:focus {
  background-color: #0a0c0d !important; }

.bg-btn-primary-bg {
  background-color: #ffa401 !important; }

a.bg-btn-primary-bg:hover, a.bg-btn-primary-bg:focus,
button.bg-btn-primary-bg:hover,
button.bg-btn-primary-bg:focus {
  background-color: #cd8400 !important; }

.bg-body-bg-hover {
  background-color: #e0e0e0 !important; }

a.bg-body-bg-hover:hover, a.bg-body-bg-hover:focus,
button.bg-body-bg-hover:hover,
button.bg-body-bg-hover:focus {
  background-color: #c7c7c7 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-secondary {
  border-color: #0000ff !important; }

.border-primary {
  border-color: #a5d5d5 !important; }

.border-primary-dark {
  border-color: #990000 !important; }

.border-primary-light {
  border-color: #990000 !important; }

.border-primary-light-hover {
  border-color: #00ff00 !important; }

.border-btn-primary-text-hover {
  border-color: #fff !important; }

.border-btn-primary-text {
  border-color: #212529 !important; }

.border-btn-primary-bg {
  border-color: #ffa401 !important; }

.border-body-bg-hover {
  border-color: #e0e0e0 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-secondary {
  color: #0000ff !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #0000b3 !important; }

.text-primary {
  color: #a5d5d5 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #71bdbd !important; }

.text-primary-dark {
  color: #990000 !important; }

a.text-primary-dark:hover, a.text-primary-dark:focus {
  color: #4d0000 !important; }

.text-primary-light {
  color: #990000 !important; }

a.text-primary-light:hover, a.text-primary-light:focus {
  color: #4d0000 !important; }

.text-primary-light-hover {
  color: #00ff00 !important; }

a.text-primary-light-hover:hover, a.text-primary-light-hover:focus {
  color: #00b300 !important; }

.text-btn-primary-text-hover {
  color: #fff !important; }

a.text-btn-primary-text-hover:hover, a.text-btn-primary-text-hover:focus {
  color: #d9d9d9 !important; }

.text-btn-primary-text {
  color: #212529 !important; }

a.text-btn-primary-text:hover, a.text-btn-primary-text:focus {
  color: black !important; }

.text-btn-primary-bg {
  color: #ffa401 !important; }

a.text-btn-primary-bg:hover, a.text-btn-primary-bg:focus {
  color: #b47300 !important; }

.text-body-bg-hover {
  color: #e0e0e0 !important; }

a.text-body-bg-hover:hover, a.text-body-bg-hover:focus {
  color: #bababa !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

::selection {
  text-shadow: none;
  background: #999; }

a {
  color: #000000;
  transition: color 0.3s;
  font-weight: bold; }

a:hover {
  color: #990000 !important;
  text-decoration: none; }

h1 {
  margin-top: 35px;
  text-align: center;
  color: #a5d5d5;
  text-transform: none;
  font-size: 80px;
  font-family: "Barlow Condensed";
  font-weight: 300;
  margin-bottom: 0px; }

h2 {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #706f6f;
  font-size: 40px;
  font-family: "Barlow Condensed";
  font-weight: 600; }

h3 {
  font-size: 1.7rem; }

html {
  text-rendering: optimizeLegibility;
  font-kerning: normal;
  font-feature-settings: "kern";
  overflow: hidden;
  overflow-y: auto; }

body {
  overflow: hidden;
  overflow-y: auto;
  overscroll-behavior: none; }
  body header {
    position: sticky;
    height: 120px;
    width: 100%;
    background: #ddc8a2; }
    body header .header__top {
      height: 120px; }
    body header nav {
      width: 100%;
      height: 80px;
      background: #004750;
      position: relative; }
      body header nav .navbar-nav .nav-item .nav-link {
        font-family: "Barlow Condensed";
        font-weight: 900;
        font-size: 20px; }
  body main {
    z-index: 50;
    flex: 1;
    width: 100%;
    min-height: calc(100vh - 164px);
    background: #ffffff;
    position: relative; }
    body main .grid-container:last-child {
      padding-bottom: 80px; }
  body footer {
    z-index: 60;
    background: transparent;
    padding: 0px;
    margin: 0px;
    position: relative;
    width: 100%;
    min-height: 50px; }
    body footer .kontakticons {
      max-width: 750px;
      margin-left: auto;
      margin-right: auto; }
    body footer .site-map li a:hover,
    body footer a:hover {
      color: #343434 !important; }
    body footer .site-map li a,
    body footer a {
      font-weight: 200 !important;
      color: #343434 !important;
      transition: color 0.3s; }
    body footer .site-map li a {
      font-weight: 900 !important; }
  body .btn-primary {
    color: #212529; }

.grid-container {
  position: relative; }
  .grid-container .content--shadow {
    position: absolute;
    top: 0px;
    width: 100%; }
  .grid-container .content--shadow:before {
    content: "";
    position: absolute;
    top: 80%;
    left: 0.35714rem;
    bottom: 1.07143rem;
    width: 50%;
    max-width: 21.42857rem;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0 15px 10px rgba(0, 0, 0, 0.2);
    z-index: -1; }
  .grid-container .container-fluid-shadow {
    overflow: hidden;
    position: relative;
    z-index: 3; }

.shd-box-main {
  position: absolute !important;
  top: 0px;
  left: 0px;
  width: 100%;
  overflow: hidden;
  height: 150px; }
  .shd-box-main .shd-box {
    width: 100%;
    height: 150px; }
  .shd-box-main .shd-box:before {
    content: " ";
    box-shadow: 0 15px 25px #c8c8c8;
    transform: rotate(-0.5deg);
    top: -150px;
    left: -15px;
    width: 100%;
    position: absolute;
    height: 150px;
    mix-blend-mode: multiply;
    transform-origin: left bottom; }

@media (max-width: 1199.98px) {
  body main {
    padding-top: 140px !important; }
    body main .row {
      margin-left: 0px;
      margin-right: 0px; }
      body main .row .col-md-12 {
        padding-right: 0px !important;
        padding-left: 0px !important; }
      body main .row .infobox {
        margin-left: 0px !important;
        margin-right: 0px !important; }
    body main .container-fluid, body main .container-xs, body main .container-sm, body main .container-md, body main .container-lg, body main .container-xl {
      padding: 0px !important; }
      body main .container-fluid .teaser, body main .container-xs .teaser, body main .container-sm .teaser, body main .container-md .teaser, body main .container-lg .teaser, body main .container-xl .teaser {
        height: calc(100vw / 16 * 9); } }

@media (max-width: 1199.98px) and (min-width: 576px) {
  html body h1 {
    font-size: 50px !important; }
  html body h2 {
    font-size: 26px !important; }
  html body #header {
    height: 100px; }
    html body #header .header__top {
      height: 100px; }
      html body #header .header__top .header__largelogo {
        top: 0px !important; }
        html body #header .header__top .header__largelogo a {
          padding-top: 10px !important;
          padding-bottom: 10px !important; }
          html body #header .header__top .header__largelogo a img {
            height: 100% !important;
            width: unset !important; }
  html body .infobox .infobox-box {
    margin-bottom: 20px !important; }
    html body .infobox .infobox-box h3 {
      margin-bottom: 0px !important; }
  html body .infobox .box-firstsection {
    height: 180px !important; } }

@media (max-width: 1199.98px) and (min-width: 576px) and (max-width: 991.98px) {
  html body .infobox .box-firstsection {
    height: 100px !important; }
  html body .infobox .box1, html body .infobox .box2, html body .infobox .box3 {
    padding-right: 90px;
    padding-left: 90px; }
  html body .news-startseite .news {
    margin-right: 90px;
    margin-left: 90px; } }

@media (max-width: 1199.98px) {
  #light-field {
    height: 140px !important; }
  .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    padding-right: 5px !important;
    padding-left: 5px !important; }
  .pb-4 {
    padding-bottom: 10px !important; }
  body {
    font-size: 1rem !important; }
    body header .shd-box-main .shd-box:before {
      top: -51px !important; }
    body header .citysilhouette {
      max-height: 50px !important; }
    body header .headerlogo picture img {
      max-height: 160px !important; }
    body header .header__top .header__largelogo {
      margin-left: 150px !important; }
    body header .logo-left {
      left: 0px !important;
      transform: translateX(0px) !important; }
      body header .logo-left .key-top {
        display: none !important; }
    body header .header__top .logo-right {
      z-index: 9999;
      height: 100px !important; }
      body header .header__top .logo-right .logo-comp {
        top: 15px !important;
        width: 120px !important;
        margin-top: 20px; } }
    @media (max-width: 1199.98px) and (max-width: 991.98px) {
      body header .header__top .logo-right .logo-comp {
        margin-top: 0px !important; } }

@media (max-width: 1199.98px) {
      body header .header__top .logo-right .sewald {
        margin-left: 30px !important; }
      body header .header__top .logo-right .muck {
        margin-right: 30px !important; }
      body header .header__top .logo-right img, body header .header__top .logo-right svg {
        height: 30px !important; }
    body main {
      padding-top: 100px !important; }
      body main .teaser {
        max-height: 300px;
        min-height: 300px; }
        body main .teaser article h1 {
          font-size: 50px !important; }
      body main .parallax-bilder {
        height: 290px !important;
        min-height: 290px !important; }
        body main .parallax-bilder picture img {
          width: unset !important;
          min-width: 100% !important;
          position: absolute; }
      body main .infobox {
        margin-left: -20px !important;
        margin-right: -20px !important; }
      body main .parallax-bilder-fixed picture img {
        min-width: 100% !important;
        min-height: 100% !important; }
      body main .tinyMCEbackground-3 {
        padding: 15px; }
        body main .tinyMCEbackground-3 p {
          font-size: 15px !important; }
      body main .events {
        display: flex; }
        body main .events .row .col-md-12 {
          padding-right: 30px !important;
          padding-left: 30px !important; }
      body main hr {
        margin-top: 5px; }
      body main h2 {
        font-size: 22px !important; }
      body main h4 {
        font-size: 22px !important; }
      body main .buttons-modul .btn-modul i {
        width: 90px;
        height: 90px;
        padding: 15px !important; }
      body main .news__content {
        font-size: 0.9rem !important; }
    body footer {
      width: 100% !important;
      min-height: 400px !important; }
      body footer .ilu {
        z-index: 1; }
      body footer .grid-container {
        z-index: 999; } }

@media (max-width: 991.98px) {
  body .header--icons {
    display: none !important; }
  body main {
    padding-top: 50px !important; }
    body main .container-fluid > .row, body main .container-xs > .row, body main .container-sm > .row, body main .container-md > .row, body main .container-lg > .row, body main .container-xl > .row {
      margin: 0px !important; }
    body main .kontakticons a {
      margin-bottom: 40px !important; }
      body main .kontakticons a i {
        justify-content: center;
        display: flex; }
    body main .tinyMCE {
      padding: 0px 10px !important; } }

@media (max-width: 767.98px) {
  body {
    font-size: 1.2rem !important; }
    body header .header__top .logo-right .muck {
      margin-right: 10px !important;
      width: 130px !important; }
    body header .header__top .logo-right .sewald {
      margin-left: 10px !important;
      width: 130px !important; } }

@media (max-width: 991.98px) {
  .parallax-bilder {
    margin-top: 5px;
    margin-bottom: 5px; }
  body main h1 {
    font-size: 30px !important; }
  body main h2 {
    font-size: 20px !important; } }

@media (max-width: 991.98px) and (max-width: 767.98px) {
  body main .gutschein-bild {
    margin-top: -20px;
    margin-bottom: -20px; }
    body main .gutschein-bild img {
      display: block;
      margin-left: auto;
      margin-right: auto; }
  body #header {
    height: 50px !important;
    max-width: 100vw !important; }
    body #header .header__top {
      height: 50px !important; }
    body #header #headerlogo {
      width: 100%;
      margin-left: 0px !important; }
      body #header #headerlogo a {
        margin-top: 10px !important; }
      body #header #headerlogo img {
        height: 33.65px !important;
        width: 375px !important; }
    body #header .navbar-toggler {
      bottom: 30px !important; }
  body .bildergalerie--article {
    width: 100vw !important;
    height: 100vh !important;
    overflow: hidden; }
    body .bildergalerie--article .teaser--bg {
      max-width: 100vw !important;
      max-height: 100vh !important; }
  body .teaser--main .teaser {
    min-height: 280px !important;
    max-height: 450px !important; }
  body footer .kontakticons-full .comp1, body footer .kontakticons-full .comp2 {
    padding-left: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  body header .shd-box-main .shd-box:before {
    height: 100px !important; }
  body header .mobile-shadow {
    width: 100%;
    position: fixed;
    height: 80px;
    bottom: 0px;
    background: transparent;
    display: flex !important;
    box-shadow: 0px 0px 10px #000;
    z-index: 9999; }
  body header .navbar-toggler {
    position: fixed;
    bottom: 20px;
    top: unset;
    left: 50%;
    max-width: 60px !important;
    transform: translateX(-50%);
    border: 1px solid #fff !important; }
  body header .header__top .logo-left .logo-signet {
    width: 80px !important;
    height: 110px !important; }
  body header .logo-right {
    position: fixed !important;
    bottom: 0px;
    top: unset !important;
    transform: unset !important;
    background: #666666;
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 10px !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    height: 80px !important;
    max-width: 100vw !important; }
    body header .logo-right .logo-comp {
      top: 2px;
      position: absolute;
      width: 130px !important; }
      body header .logo-right .logo-comp .logo-comp-font {
        color: #fff; }
    body header .logo-right .sewald {
      position: absolute;
      left: 0px;
      margin-left: 5px !important; }
    body header .logo-right .muck {
      margin-right: 5px !important;
      margin-left: 0px !important;
      right: 0px !important; }
    body header .logo-right img, body header .logo-right svg {
      max-width: 30px !important; }
      body header .logo-right img .st0, body header .logo-right svg .st0 {
        fill: #fff !important; }
  body header .logo-right {
    right: 0px !important; }
    body header .logo-right svg path {
      fill: #fff !important; }
  body header nav {
    height: auto !important; }
    body header nav .nav-shadow img {
      height: 25px !important; }
    body header nav .navbar-nav {
      padding-bottom: 20px !important; }
      body header nav .navbar-nav .navbar-nav {
        padding-bottom: 0px !important; }
    body header nav .nav-item {
      margin-bottom: 0px;
      padding-bottom: 0px !important;
      padding-top: 5px !important; }
      body header nav .nav-item .nav-link {
        padding: 0px !important; }
      body header nav .nav-item .nav-item {
        padding-top: 0px !important; }
        body header nav .nav-item .nav-item .nav-link {
          font-size: 20px !important; }
    body header nav .navbar-nav {
      box-shadow: 0px 0px 10px #000; }
    body header nav #navbarSupportedContent {
      background: #fff !important;
      margin-top: 0px !important;
      padding-top: 0px !important; }
      body header nav #navbarSupportedContent .navbar-nav {
        margin-top: 0px !important; }
    body header nav .navbar-collapse {
      position: fixed;
      bottom: 80px !important;
      top: unset !important;
      height: auto !important;
      width: 100% !important;
      background: unset !important;
      padding: 20px 0px 20px;
      padding-bottom: 0px !important; }
      body header nav .navbar-collapse ul {
        flex-direction: column !important; }
        body header nav .navbar-collapse ul li {
          width: 100% !important;
          padding: 20px 0px; }
          body header nav .navbar-collapse ul li a {
            font-size: 22px !important; }
          body header nav .navbar-collapse ul li ul li {
            padding: 0px;
            margin: 0px;
            text-transform: none !important; }
  body main .infobox .infobox-box {
    margin-bottom: 30px; }
    body main .infobox .infobox-box .box-firstsection h3 {
      margin-bottom: 0px !important; }
  body .news-startseite .news {
    width: calc(100% - 20px);
    margin-bottom: 30px; }
  body footer {
    min-height: 520px !important;
    padding-bottom: 100px !important; } }

@media (max-width: 575.98px) {
  
  .container-fluid,
  .container-xs,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding-right: 10px !important;
    padding-left: 10px !important; } }
