.news-startseite {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 40px 0 20px;
  .news {
    background: #fff;
    padding: 20px 20px 10px;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% / 3 - 20px);
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    .news__media {
      .news__media-img-container {
        display: flex;
        flex-direction: column;
        picture {
          display: flex;
          flex-direction: column;
          img {
            width: calc(200% / 16 * 9);
            height: calc(100% / 16 * 9);
          }
        }
      }
    }
    a {
      text-decoration: none;
      color: #000;
      font-weight: 400;
      flex-direction: column;
      height: 100%;
      display: flex;
    }
    picture {
      max-width: 100%;
      img {
        max-width: 100%;
      }
    }
    .news__headline {
      h4 {
        color: #EB2323;
        margin: 10px 0px 10px 10px;
      }
    }
    .news__content {
      padding: 0 0 0;
      font-size: 18px;
      flex-grow: 2;
    }
    .news--verlinkung {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      svg {
        max-width: 60px;
        max-height: 60px;
        height: 60px;
        width: 60px;
        z-index: 1;
      }
    }
    .news--verlinkung-line {
      width: 100%;
      border-top: 1px solid #ccc;
      height: 1px;
      position: absolute;
    }
  }
}