.site-map {
  display: flex;
  list-style-type: none;
  margin-left: 0px;
  padding-left: 0px;
  li {
    h5 {

    }
    a {
      line-height: 21px;
      font-size: 21px;
      color: #dfaf46 !important;
      white-space: normal;
      text-shadow: -1px -1px 0 #b01a1a;
      font-weight: 900;
      font-size: 21px;
      text-transform: uppercase;
    }
  }
}
.sitemap--nebeneinander {
  flex-direction: row;
  justify-content: space-around;
}