@include media-breakpoint-down(md) {
  body {
    .header--icons {
      display: none !important;
    }
    main {
      padding-top: 50px !important;
      .container-fluid {
        >.row {
          margin: 0px !important;
        }
      }
      .kontakticons {
        a {
          margin-bottom: 40px !important;
          i {
            justify-content: center;
            display: flex;
          }
        }
      }
      .tinyMCE {
        padding: 0px 10px !important;
      }
    }
    .mobile-footer {
      
    }
  }
}
@include media-breakpoint-down(sm) {
  body {
    font-size: 1.2rem !important;
    header .header__top .logo-right {
      .muck {
        margin-right: 10px !important;
        width: 130px !important;
      }
      .sewald {
        margin-left: 10px !important;
        width: 130px !important;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .parallax-bilder {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  body {
    main {
      h1 {
        font-size: 30px !important;
      }
      h2 {
        font-size: 20px !important;
      }
    }
    @include media-breakpoint-down(sm) {
      main {
        .gutschein-bild {
          margin-top: -20px; margin-bottom: -20px;
          img {
            display: block; margin-left: auto; margin-right: auto;
          }
        }
      }
      #header {
        height: 50px !important;
        max-width: 100vw !important;

        .header__top {
          height: 50px !important;
        }

        #headerlogo {
          width: 100%;
          margin-left: 0px !important;
          a {
            margin-top: 10px !important;
          }

          img {
            height: 33.65px !important;
            width: 375px !important;
          }
        }

        .navbar-toggler {
          bottom: 30px !important;
        }
      }
      .bildergalerie--article {
        width: 100vw !important;
        height: 100vh !important;
        overflow: hidden;

        .teaser--bg {
          max-width: 100vw !important;
          max-height: 100vh !important;
        }
      }

      .teaser--main {
        .teaser {
          min-height: 280px !important;
          max-height: 450px !important;
        }
      }

      footer {
        .kontakticons-full {
          .comp1, .comp2 {
            padding-left: 30px !important;
            padding-bottom: 30px !important;
          }
        }
      }
    }
    header {
      .shd-box-main {
        .shd-box:before {
          height: 100px !important;
        }
      }
      .mobile-shadow {
        width: 100%;
        position: fixed;
        height: 80px;
        bottom: 0px;
        background: transparent;
        display: flex !important;
        box-shadow: 0px 0px 10px #000;
        z-index: 9999;
      }
      .navbar-toggler {
        position: fixed;
        bottom: 20px;
        top: unset;
        left: 50%;
        max-width: 60px !important;
        transform: translateX(-50%);
        border: 1px solid #fff !important;
      }
      .header__top {
        .logo-left {
          .logo-signet {
            width: 80px !important;
            height: 110px !important;
          }
        }
      }
      .logo-right {
        position: fixed !important;
        bottom: 0px;
        top: unset !important;
        transform: unset !important;
        background: #666666;
        width: 100% !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        padding-left: 10px !important;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        height: 80px !important;
        max-width: 100vw !important;
        .logo-comp {
          top: 2px;
          position: absolute;
          width: 130px !important;
          .logo-comp-font {
            color: #fff;
          }
        }
        .sewald {
          position: absolute;
          left: 0px;
          margin-left: 5px !important;
        }
        .muck {
          margin-right: 5px !important;
          margin-left: 0px !important;
          right: 0px !important;
        }
        img, svg {
          max-width: 30px !important;
          .st0 {
            fill: #fff !important;
          }
        }
      }
      .logo-right {
        right: 0px !important;
        svg {
          path {
            fill: #fff !important;
          }
        }
      }
      nav {
        height: auto !important;
        .nav-shadow {
          img {
            height: 25px !important;
          }
        }
        .navbar-nav {
          padding-bottom: 20px !important;
          .navbar-nav {
            padding-bottom: 0px !important;
          }
        }
        .nav-item {
          margin-bottom: 0px;
          padding-bottom: 0px !important;
          padding-top: 5px !important;
          .nav-link {
            padding: 0px !important;
          }
          .nav-item {
            padding-top: 0px !important;
            .nav-link {
              font-size: 20px !important;
            }
          }
        }
        .navbar-nav {
          box-shadow: 0px 0px 10px #000;
        }
        #navbarSupportedContent {
          background: #fff !important;
          margin-top: 0px !important;
          padding-top: 0px !important;
          .navbar-nav {
            margin-top: 0px !important;
          }
        }
        .navbar-collapse {
          position: fixed;
          bottom: 80px !important;
          top: unset !important;

          height: auto !important;
          width: 100% !important;
          background: unset !important;
          padding: 20px 0px 20px;
          padding-bottom: 0px !important;
          ul {
            flex-direction: column !important;
            li {
              width: 100% !important;
              padding: 20px 0px;
              a {
                font-size: 22px !important;
              }
              ul {
                li {
                  padding: 0px;
                  margin: 0px;
                  text-transform: none !important;
                }
              }
            }
          }
        }
      }
    }
    main {
      .infobox {
        .infobox-box {
          margin-bottom: 30px;
          .box-firstsection {
            h3 {
              margin-bottom: 0px !important;
            }
          }
        }
      }
    }
    .news-startseite {
      .news {
        width: calc(100% - 20px);
        margin-bottom: 30px;
      }
    }
    footer {
      min-height: 520px !important;
      padding-bottom: 100px !important;
    }
  }
}