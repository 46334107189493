@include media-breakpoint-down(lg) {
  body {
    main {
      padding-top: 140px !important;
      .row {
        margin-left: 0px;
        margin-right: 0px;
        .col-md-12 {
          padding-right: 0px !important;
          padding-left: 0px !important;
        }
        .infobox {
          margin-left: 0px !important;
          margin-right: 0px !important;
        }
      }
      .container-fluid {
        padding: 0px !important;
        .teaser {
          height: calc(100vw / 16 * 9);
        }
      }
    }
  }
}