.infobox {
  margin-left: -30px !important;
  margin-right: -30px !important;
  margin-bottom: 50px;
  background: transparent !important;

  .infobox-box {
    box-shadow: 0px 0px 5px #e5e5e5;
    background: #fff;
    padding: 10px;
    .box-image {
      width: 100%;
      picture {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
    a {
      display: flex;
      justify-content: center;
      .fontawsome-icon {
        width: 32px;
        svg,img {
          color: #b3b1b2;
          width: 32px;
          height: auto;
          transition: 0.3s color;
        }
      }
    }
    a:hover {
      .fontawsome-icon {
        svg {
          color: #e1e1e1 !important;
        }
      }
    }
  }

  h2 {
    color: #fff;
    margin-top: 30px;
    font-size: 20px;
    margin-bottom: 10px;
  }
  h3 {
    color: #009cc5;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
  }
  .content {
    color: #ffffff;
    margin-bottom: 30px;
  }
  .box-firstsection {
    height: 140px;
  }
  .box-lastsection {
    color: #ffffff;
    a {
      color: #ffffff;
      img {
        transition: opacity 0.3s;
      }
    }
    a:hover {
      color: rgba(255,255,255,0.75) !important;
      img {
        opacity: 0.75;
      }
    }
  }
  .box-pic {
    width: 100%;
    img {
      width: 100%;
      border: 10px solid #ffffff;
    }
  }
}