.bildmittext-box {
  .bildbox {
    border: 10px solid #fff;
    padding: 0px !important;
    box-shadow: 0 0 5px #e5e5e5;
    picture {
      position: relative;
      width: 100%;
      height: 100%;
      img {
        position: relative;
        width: 100%;
      }
    }
  }
}